import { defineStore } from 'pinia';
import { ILayout } from '@/stores/formBuilder/interfaces/ILayout';
import { TScale } from '@/stores/formBuilder/interfaces/TScale';
import { useI18n } from 'vue-i18n';
import { TBuilderOption } from '@/components/dynamicForm/interfaces/TBuilderOption';

interface FormBuilderComponentState {
    storeAvailableComponents: any[];
    additionalSettings: TBuilderOption[];
    createItemId: string | null;
    moveItemId: string | null;
    currentLayouts: Record<string | number, ILayout>;
    layouts: ILayout[];
    currentScale: TScale;
}

export const useFormBuilderComponentStore = defineStore('formBuilderComponent', {
    state: (): FormBuilderComponentState => ({
        storeAvailableComponents: [],
        additionalSettings: [],
        createItemId: null,
        moveItemId: null,
        currentLayouts: {},
        layouts: [
            { width: 0, value: 'default', text: 'formBuilder.mobile', valueForView: 400 },
            { width: 640, value: 'sm', text: 'formBuilder.tablet', valueForView: 700 },
            { width: 1024, value: 'lg', text: 'formBuilder.desktop', valueForView: 1100 }
        ],
        currentScale: { text: '100%', value: 1 }
    }),
    getters: {
        getAvailableComponents(state): any[] {
            return state.storeAvailableComponents;
        },
        getItem:
            state =>
            (id: string): any | undefined => {
                return state.storeAvailableComponents.find(element => element.id === id);
            },
        getCreatedItemId(state): string | null {
            return state.createItemId;
        },
        getMoveItemId(state): string | null {
            return state.moveItemId;
        },
        getLayouts(state): ILayout[] {
            return state.layouts.map(layout => ({
                ...layout,
                text: useI18n().t(layout.text)
            }));
        },
        getCurrentLayouts(state): Record<string, ILayout> {
            return state.currentLayouts;
        },
        getCurrentScale(state): TScale {
            return state.currentScale;
        },
        getAdditionalSettings(): TBuilderOption[] {
            return this.additionalSettings || [];
        },
    },
    actions: {
        setAdditionalSettings(settings: TBuilderOption[]): void {
            this.additionalSettings = settings;
        },
        clearAdditionalSettings(): void {
            this.additionalSettings = [];
        },
        setCreateItemId(id: string): void {
            this.createItemId = id;
        },
        setMoveItemId(id: string): void {
            this.moveItemId = id;
        },
        setAvailableComponentsItem(element: any): void {
            this.storeAvailableComponents.push(element);
        },
        setAvailableComponents(items: any[]): void {
            this.storeAvailableComponents = items;
        },
        changeItem(item: any): void {
            const index = this.storeAvailableComponents.findIndex(element => element.id === item.id);
            if (index !== -1) {
                this.storeAvailableComponents[index] = item;
            }
        },
        setCurrentLayout(layout: ILayout, id: string | number): void {
            this.currentLayouts[id] = layout;
        },
        removeLayout(id: string) {
            delete this.currentLayouts[id];
        },
        setCurrentScale(scale: TScale): void {
            this.currentScale = scale;
        }
    }
});
