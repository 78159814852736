<template>
    <div class="df-panels-container df-panels-container-tabs h-full">
        <div class="df-panels-tabs h-full">
            <base-tabs-wrapper>
                <base-tab title="Components" id="Components">
                    <accordion-wrapper :items="availableCategories" :isMultiple="true" :show-title-border="true" :rememberState="true" :id="'entitiesComponentList'">
                        <template v-slot:title="{ item }">
                            <div class="flex font-medium options__group" @click="toggleActiveCategory(item.id)">
                                <span class="text-base">{{ item?.label }}</span>
                            </div>
                        </template>
                        <template v-slot:content="{ item }">
                            <div class="w-full flex flex-col overflow-hidden overflow-y-auto h-full">
                                <components-list-item v-for="comp in filteredElements(item.id)" :key="comp.id" :item="comp"></components-list-item>
                            </div>
                        </template>
                    </accordion-wrapper>
                </base-tab>
                <base-tab title="Tree" id="Tree"></base-tab>
            </base-tabs-wrapper>
        </div>
        <div class="flex absolute flex-col df-top-left-tools-container">
            <div class="flex flex-col vfb-tool">
                <!-- <BaseButton></BaseButton> -->
                <div
                    class="flex relative items-center justify-center cursor-pointer vfb-tool-item"
                    :class="{ 'vfb-tool-item__active': toolsActiveItem === 'editor' }"
                    @click="setEditorMode('editor')"
                    v-tooltip="'Editor mode'">
                    <base-icon style="height: 20px" viewBox="0 0 24 24" name="editPencil"></base-icon>
                </div>
                <div
                    class="flex relative items-center justify-center cursor-pointer vfb-tool-item"
                    :class="{ 'vfb-tool-item__active': toolsActiveItem === 'viewer' }"
                    @click="setEditorMode('viewer')"
                    v-tooltip="'Viewer mode'">
                    <base-icon style="height: 20px" viewBox="0 0 24 24" name="eye"></base-icon>
                </div>

                <div
                    class="flex relative items-center justify-center cursor-pointer vfb-tool-item"
                    :class="{ 'vfb-tool-item__active': toolsActiveItem === 'schema' }"
                    @click="setEditorMode('schema')"
                    v-tooltip="'Show schema'">
                    <base-icon style="height: 24px" viewBox="0 0 28 28" name="fieldTypeJson"></base-icon>
                </div>
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="valideAll" v-tooltip="'Validate'">
                    <base-icon style="height: 24px" viewBox="0 0 28 28" name="fieldLock"></base-icon>
                </div>
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="undo" v-tooltip="'Undo'">
                    <base-icon style="height: 24px" viewBox="0 0 28 28" name="schedule"></base-icon>
                </div>
                <!-- <div
          class="flex relative items-center justify-center cursor-pointer vfb-tool-item"
          @click="redo"
          v-tooltip="'Redo'"
        >
          <base-icon
            style="height: 24px"
            viewBox="0 0 28 28"
            name="fieldLock"
          ></base-icon>
        </div> -->
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="showFormModul" v-tooltip="'Form module'">
                    <base-icon style="height: 24px" viewBox="0 0 24 24" name="annotation"></base-icon>
                </div>
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="SaveSchema" v-tooltip="'Save schema'">
                    <base-icon style="height: 24px" viewBox="0 0 24 24" name="savead"></base-icon>
                </div>
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="clearSchema" v-tooltip="'Clear schema'">
                    <base-icon style="height: 24px" viewBox="0 0 24 24" name="delete"></base-icon>
                </div>
                <div v-if="multilingual" class="flex relative items-center justify-center vfb-tool-item cursor-pointer border border-gray-100" v-tooltip="'Change locale'">
                    <BaseSelect
                        :size="32"
                        class="flex w-full h-full"
                        :items="availableLocales"
                        :value="currentLocale"
                        :tags="false"
                        :search="false"
                        :dropdownWidth="80"
                        :multiple="false"
                        :valueTypeObject="false"
                        :object="true"
                        labelProp="title"
                        :dropdownWidthEqual="false"
                        noBorder
                        :clearSingle="false"
                        :hideToggleIcon="true"
                        @change="changeLocale" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ComponentsListItem from '@/components/dynamicForm/components/formBuilder/componentsList/ComponentsListItem.vue';
import { BaseTabsWrapper, BaseTab, AccordionWrapper } from 'uikit';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseSelect from '@/components/ui/baseSelect/BaseSelect.vue';
import { IBuilderElement } from '@/components/dynamicForm/interfaces/IBuilderElement';
import { IFormConfig } from '@/components/dynamicForm/interfaces/IFormConfig';
import { TEditorMode } from '@/components/dynamicForm/interfaces/TEditorMode';
import { computed, ref } from 'vue';

interface IProps {
    availableComponents: IBuilderElement[];
    formSettings: IFormConfig;
}

interface ICategory {
    id: string;
    label: string;
}

const props = defineProps<IProps>();
const emits = defineEmits<{
    setEditorMode: [mode: TEditorMode];
    valideAll: [];
    clearSchema: [];
    saveSchema: [event: MouseEvent];
    showFormModule: [];
    customAction: [action: 'undo' | 'changeLocale', data?: string];
}>();

const toolsActiveItem = ref('editor');
const activeCategories = ref<string[]>([]);
const fieldsCategories = ref<ICategory[]>([
    { id: 'entityFields', label: 'Entity fields' },
    { id: 'fields', label: 'Fields' },
    { id: 'static', label: 'Static' },
    { id: 'structure', label: 'Structure' },
    { id: 'widgets', label: 'Widgets' }
]);

const availableCategories = computed(() => {
    if (!props.availableComponents) return [];
    return fieldsCategories.value.filter(el => props.availableComponents.some(component => component.category === el.id));
});

const filteredElements = computed(() => {
    return (categoryId: string) => props.availableComponents.filter(el => el.category === categoryId);
});

const multilingual = computed(() => props.formSettings?.locales?.length > 1);
const currentLocale = computed(() => props.formSettings?.locale);
const availableLocales = computed(() => props.formSettings?.availableLocales?.filter(el => props.formSettings?.locales?.includes(el.id)));

const setEditorMode = (mode: TEditorMode) => {
    toolsActiveItem.value = mode;
    emits('setEditorMode', mode);
};

const valideAll = () => {
    emits('valideAll');
};

const clearSchema = () => {
    emits('clearSchema');
};

const SaveSchema = (event: MouseEvent) => {
    emits('saveSchema', event);
};

const showFormModul = () => {
    emits('showFormModule');
};

const undo = () => {
    emits('customAction', 'undo');
};

const changeLocale = (newValue: string) => {
    emits('customAction', 'changeLocale', newValue);
};

const toggleActiveCategory = (categoryId: string) => {
    const index = activeCategories.value.indexOf(categoryId);
    if (index === -1) {
        activeCategories.value.push(categoryId);
    } else {
        activeCategories.value.splice(index, 1);
    }
};
</script>

<style scoped lang="scss">
.df-panels-container-tabs .df-panels-tabs {
    display: flex;

    .df-panels-tab {
        flex: 1 1 0%;
        cursor: pointer;
        border-bottom-width: 1px;
        border-right-width: 1px;
        --tw-border-opacity: 1;
        border-right-color: rgb(226 232 240 / var(--tw-border-opacity));
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        text-align: center;

        &.is-active {
            --tw-border-opacity: 1;
            border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        }

        &.is-inactive {
            --tw-border-opacity: 1;
            border-bottom-color: rgb(226 232 240 / var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgb(241 245 249 / var(--tw-bg-opacity));
        }
    }
}

.df-top-left-tools-container {
    top: 40px;
    right: -3rem;
    background-color: #fff;

    .vfb-tool {
        border-radius: 0.25rem;
        border-width: 1px;

        .vfb-tool-item {
            height: 32px;
            width: 32px;
        }

        .vfb-tool-item__active {
            background-color: var(--color-modern-primary);
        }
    }
}
</style>
